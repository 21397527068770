import { Component, Vue, Prop } from 'vue-property-decorator'

//model
import { PainelIconItem } from '@/molds/ListPanelIconsModel';

//warppets
import { NotificationList, wIcon, Autocomplete, SizedBox, WColumns, WColumn, IconList, Modal } from '@/components/wrappers';

@Component({
	components:{
		wIcon,
		Modal,
		SizedBox,
		IconList,
		Autocomplete,
		WColumns, WColumn,
		NotificationList,
	}
})
export default class NotificationModal extends Vue { 
	@Prop() painelIconItens!: PainelIconItem[]

	emitConfirm(item: PainelIconItem): void {
		this.$emit('confirm', item)
	}

	emitRefuse(item: PainelIconItem): void {
		this.$emit('refuse', item)
	}

	emitRefresh(): void {
		this.$emit('refresh')
	}
	
	emitClear(): void {
		this.$emit('clear')
		this.$emit('close')
	}
}
