import { Component, Vue, Watch } from 'vue-property-decorator'

const npAuth = 'auth';
const npNotification = 'notification';

//Vuex
import { Action, Getter } from 'vuex-class';

import { GetBaseName } from '@/config'

//models
import { AutocompleteModel } from '@/components/wrappers/molds/AutocompleteModel';
import { ListPanelIconsModel, PainelIconItem } from '@/molds/ListPanelIconsModel';

//components
import notificationModal from '@/components/notificationModal/NotificationModal.vue'

//warppets
import { wIcon, Autocomplete, SizedBox, WColumns, WColumn, IconList, Modal } from '@/components/wrappers';

@Component({
	components:{
		wIcon,
		Modal,
		SizedBox,
		IconList,
		Autocomplete,
		WColumns, WColumn,
		notificationModal,
	}
})
export default class Navbar extends Vue {

	@Action('logout', { namespace: npAuth }) dispatchLogout: any;
	
	@Action('addNotification', { namespace: npNotification }) addNotification: any;
	@Action('removeNotification', { namespace: npNotification }) removeNotification: any;
	@Action('clearNotification', { namespace: npNotification }) clearNotification: any;
	@Getter('notifications', { namespace: npNotification }) notifications!: PainelIconItem[];

	get isHome(): boolean {
		return this.$route.path == '/home'
	}

	get baseName(): string{
		return GetBaseName();
	}
	
	navBarItens = [
		{
			to: { path: '/home' },
			title: "Ir para tela inicial",
			label: "Home",
			icon: "home",
		},
		{
			to: { path: '/new-process' },
			title: "Criar processo",
			label: "Novo",
			icon: "file-plus"
		},
		{
			to: { path: '/search-process' },
			title: "Pesquisar processos",
			label: "Processos",
			icon: "file-search",
		},
		{
			to: { path: '/search-users' },
			title: "Pesquisar e gerenciar usuários",
			label: "Usuários",
			icon: "account-supervisor",
		},
		{
			to: { path: '/group-admin', query:{ index: 0 } },
			title: "Pesquisar e gerenciar grupos",
			label: "Grupos",
			icon: "account-group",
		},
		{
			to: { path: '/search-profile-perm' },
			title: "Pesquisar e gerenciar perfil de permissões",
			label: "Permissões",
			icon: "shield-account",
		},
	]

	openModal(): void {
    this.$buefy.modal.open({
      parent: this,
      component: notificationModal,
      props: {
        painelIconItens: this.notifications
      },
      events: {
        confirm: (item: PainelIconItem) => {
          this.removeNotification(item)
        },
        refuse: (item: PainelIconItem) => {
          this.removeNotification(item)
        },
        refresh: () => {
          console.log("refresh");
        },
        clear: () => {
					this.clearNotification()
        },
      }
    })
  }

	onSidebar(): void {
		this.$emit("onSidebar")
	}

	logout(){
		this.dispatchLogout()
		this.$router.replace("/")
	}
	
	select(item: any): string {
		if(this.$route.path == item.to.path){
			return "navbar-item-select"
		}
		return ""
	}

	toHome(): void {
		this.$router.push('/home')
	}

	updateInput(value: string): void {
		// this.autocomplete.loading = true
		this.autocomplete.value = value
	}

	disableLoading(){
		// this.autocomplete.loading = false
	}

	selectedOption(selected: string): void {
		this.disableLoading()
		this.autocomplete.selected = selected
	}
	
	listPanelIconsModel: ListPanelIconsModel = {
		fieldName: "",
		rows: this.notifications
	}

	autocomplete: AutocompleteModel = {
		value: "",
		selected: "",
		loading: false,
		icon: "magnify",
		placeholder: 'processo/ano',
		messageNotFound: "Processo não encontrado :(",
		options: this.options
	}

	get options(): string[] {
		return [
			'12154/2021',
			'1458/2020',
			'8465/2020',
			'5456/2020',
			'7845/2020',
			'159/2020',
			'14745/2017',
			'8552/2017',
			'2527/2019',
			'2572/2019',
			
		]
	}
	
}
