import { Component, Vue } from 'vue-property-decorator'

//components
import PrivateContent from '@/components/privateContent/PrivateContent.vue'

//molds
import { User } from '@/molds/User';
import { Form } from '@/components/wrappers/molds/Form';
import { TableOptions, WolfApiData } from '@/components/wrappers/molds/TableOptions';

//classes
import { Alert } from '@/services/notification';

//Vuex
const namespaceUser = 'user';
const namespacePermission = 'permission';
import { Action, Getter } from 'vuex-class';

import { convertDataPtBr } from '@/utils'

//wrappers
import {
	WForm, 
	Container, 
	Box, 
  WButton,
  WHr,
	WSubtitle,
	WTable,
	Loader,
	WColumn,
	WColumns,
	SizedBox
} from '@/components/wrappers';

@Component({
	components: {
		WHr,
		Box,
		WForm,
		WButton,
		WSubtitle,
		Container,
		PrivateContent,
		WTable,
		Loader,
		WColumn,
		WColumns,
		SizedBox
	}
})
export default class SearchUsers extends Vue {
	
	@Action('fetchUsers', { namespace: namespaceUser }) fetchUsers: any;
	
	@Getter('users', { namespace: namespaceUser }) users!: WolfApiData;
	@Getter('error', { namespace: namespaceUser }) error!: any;

	@Action('fetchPermissionProfiles', { namespace: namespacePermission }) fetchPermissionProfiles: any;
	@Getter('permissionProfiles', { namespace: namespacePermission }) permissionProfiles!: WolfApiData;
	@Getter('error', { namespace: namespacePermission }) errorPermProfile!: any;


	isSearch = false
	alert = new Alert(this.$store)
	result!: WolfApiData
	isLoading = false

	paramQuery!: string
	showTable = false
	perPage = 10

	mounted(){
		document.title = 'Pesquisar Usuários'
	}

	startTable(): void {
		this.showTable = true
	}

	changePage(page: number): void {
		this.dispatchForm(`${this.paramQuery}&_page=${page}`)
	}
	
	async dispatchForm(paramQuery: string): Promise<void> {
		this.isLoading = true
		this.paramQuery = paramQuery
		await this.fetchUsers(`${paramQuery}&_limit=${this.perPage}`)
		
		if(this.error){
			this.alert.apiMessage("Não foi possível pesquisar.", this.error, "danger")
		}
		else{			
			if(this.users.list.length == 0){
				this.alert.warning("Nenhum usuário encontrado")
			}else{
				await this.dispatchPermissionProfiles()

				window.scroll({
					top: 500,
					behavior: 'smooth'
				});				
			}
			this.isSearch = true
		}
		this.isLoading = false
	}

	async dispatchPermissionProfiles(): Promise<void> {
		await this.fetchPermissionProfiles("?_limit=100")
		
		if(this.errorPermProfile){
			this.alert.apiMessage("Não foi possível carregar nome das permissões.", this.errorPermProfile, "danger")
		}
	}

	tableSelected(user: User){
		this.$router.push({
			path: `/user/${user.idUser}`, 
			// params: { id: `${user.idUser}` } 
		})
	}

	getStatusName(status: string): string {
		switch (status) {
			case "0":
				return 'Desativado'
			case "1":
				return 'Cadastrado'
			case "2": //Pendente de Autorização
				return 'Pendente'
			case "3":
				return 'Ativo'
			case "4":
				return 'Afastado'
			case "5":
				return 'Desativado'
			default:
				return status
		}
	}

	getPermissionName(idPermission: string | null): string | null {
		for (let i = 0; i < this.permissionProfiles.list.length; i++) {
			if(this.permissionProfiles.list[i].idPermission == idPermission){
				return this.permissionProfiles.list[i].name
			}
		}
		return idPermission
	}

	// ############# Form ##############
	get form(): Form {
		return {
			flex: false,
			indent: true,
			paramQuery: true,
			grid: [
				[
					{
						fieldName: "name",
						type: "text",
						label: "Nome",
						placeholder:"Nome",
						maxlength: 50,
					},
					{
						fieldName: "login",
						type: "text",
						label: "Login/email",
						placeholder:"Login/email",
						maxlength: 30,
						required: false
					},
				],
				[
					{
						fieldName: "document",
						type: "text",
						label: "Documento",
						placeholder:"Documento",
						maxlength: 15,
					},
					{
						fieldName: "birthDate",
						label: "Nascimento",
						type: "date",
						maxlength: 10,
					},
				],
				[
					{
						fieldName: "status",
						label: "Status",
						type: "select",
						maxlength: 11,
						value: "3",
						options:[
							{
								label: "Desativado",
								value: "0"
							},
							{
								label: "Cadastrado",
								value: "1"
							},
							{
								label: "Pendente de Autorização",
								value: "2"
							},
							{
								label: "Ativo",
								value: "3"
							},
							{
								label: "Afastado",
								value: "4"
							},
							{
								label: "Desativado (temporariamente)",
								value: "5"
							},
							{
								label: "Todos",
								value: ""
							},
						]
					},
				]
			]
		}
	}

	get convertDateAndStatus(): WolfApiData {
		const list = []
		let user!: User
		for (let i = 0; i < this.users.list.length; i++) {
			user = this.users.list[i]
			user.birthDate = convertDataPtBr(user.birthDate)
			user.status = this.getStatusName(user.status)
			user.idPermission = this.getPermissionName(user.idPermission)
			if(user.idPermission == null){
				user.idPermission = "Sem permissão"
			}
			list.push(user)
		}

		this.result = this.users
		this.result.list = list;

		return this.result
	}

	// ############# Table ##############
	get	table(): TableOptions {
		return {	
			paginated: true,
			perPage: this.perPage,
			wolfApiData: this.convertDateAndStatus,
			columns: [
				{
					field: 'idUser',
					label: 'ID',
					numeric: true,
					sortable: true
				},
				{
					field: 'name',
					label: 'Nome',
					sortable: true
				},
				{
					field: 'login',
					label: 'Login/email',
					sortable: true,
				},
				{
					field: 'document',
					label: 'Documento',
					sortable: true
				},
				{
					field: 'birthDate',
					label: 'Data de Nascimento',
					sortable: true
				},
				{
					field: 'idPermission',
					label: 'Perf.Permissão',
					sortable: true
				},
				{
					field: 'status',
					label: 'Status',
					sortable: true
				}
			]
		}
	}
}
