import { Component, Vue } from 'vue-property-decorator'

//components
import Navbar from '@/components/navbar/Navbar.vue'
import Footer from '@/components/footer/Footer.vue'

@Component({
  components: {
    Navbar,
		Footer
  }
})
export default class PrivateContent extends Vue { }
