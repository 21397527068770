import { NotificationProgrammatic as Notification } from 'buefy'
// import { ToastProgrammatic as Toast } from 'buefy'
import { DialogProgrammatic as Dialog } from 'buefy'

import { PainelIconItem } from '@/molds/ListPanelIconsModel';

export class Alert {
	private store: any

	constructor(store: any){
		this.store = store
	}

	addNotification(painelIconItem: PainelIconItem){		
		if(this.store){
			painelIconItem.label = painelIconItem.label.replace("<br/>", "")
			this.store.dispatch('notification/addNotification', painelIconItem)
		}
	}

	success(message: string): void {
		Notification.open({
			duration: 2500,
			message: message,
			position: 'is-top-right',
			type: 'is-success',
		})
	}

	warning(message: string): void {
		Notification.open({
			duration: 7000,
			message: message,
			position: 'is-top-right',
			type: 'is-warning',
		})
	}

	verifyConnection(error: any): boolean {
		try {
			if(error.toJSON().message === 'Network Error'){
				const msg = 'Sem conexão com internet. Atualize a página ou tente mais tarde';
				this.warning(msg)
				console.log(error);
				this.addNotification({
					label: msg,
					error: true
				})
				return false
			}
		} catch (err) {
			this.danger("Desculpe, ocorreu um erro inesperado: 5000")
			return false
		}
		return true
	}

	apiMessage(message: string, error: any, type: string): void {
		if(!this.verifyConnection(error)){
			return
		}

		const msgError = error?.response?.data?.errors[0].msg
		let msg = ""
		if(msgError){
      if(msgError != ''){
        msg = `${error}`;
      }
      else{
        msg = `${msgError},<br> ${error}`;
      }
		}else{
			msg = message
		}
		
		this.addNotification({
			label: msg,
			error: true
		})
		
		Notification.open({
			duration: 7000,
			message: msg,
			position: 'is-top-right',
			type: `is-${type}`,
		})
	}

	danger(message: string, error?: string): void {
		let msg = "";
		
		if(error){
      msg = `${message}, ${error}`;
		}else{
			msg = message
		}

		this.addNotification({
			label: msg,
			error: true
		})

		Notification.open({
			duration: 7000,
			message: msg,
			position: 'is-top-right',
			type: 'is-danger',
		})
	}

	notification(type: string, message: string): void {
		Notification.open({
				duration: 7000,
				message: message,
				position: 'is-top-right',
				type: type,
				// hasIcon: true
		})
	}

  defaultSuccess(): void {
		Notification.open({
				duration: 7000,
				message: 'Operação realizada com sucesso',
				position: 'is-top-right',
				type: 'is-success',
				// hasIcon: true
		})
	}

	dialogConfirm(message: string, executeConfirm: Function): void {
    Dialog.confirm({
      message: message,
      canCancel: ['button'],
      onConfirm: () => {
        executeConfirm()
      }
    })
  }

}